import React from "react";

import { Box } from "@chakra-ui/core";
import { BaseWrapper } from "../components/BaseWrapper";
import useWindowDimensions from "../hooks/useWindowDimensions";

const InnovationNetwork: React.FC = () => {
  const { height } = useWindowDimensions();

  return (
    <BaseWrapper>
      <Box
        margin="0"
        padding="0"
        overflowY="scroll"
        overflowX="hidden"
        width="100%"
        h="100%"
      >
        {/* <ApplyModal /> */}
        {/* https://docs.kumu.io/guides/share-and-embed.html use query params to customise the embed */}
        <iframe
          title="CORE Innovation Ecosystem"
          src="https://embed.kumu.io/579880b56e9bdb82f032e2990b04e23b?settings=0"
          width="100%"
          height={height}
        ></iframe>
      </Box>
    </BaseWrapper>
  );
};

export default InnovationNetwork;
